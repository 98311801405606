/// <reference types="matrixrequirements-type-declarations" />
import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldDateParams };
export { FieldDate };

interface IFieldDateParams extends IPrintFieldParams {
    formatString: string; // Overwrite the format string for the date value
    class: string; // default:"". additional class for outermost container
}

class FieldDate implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("date");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `
<h2>date</h2>
<pre>
    formatString: string // If set use this format string for formatting the date, for example yyyy-MM-dd
</pre>
        `;
    }

    getName() {
        return "date";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldDateParams>paramsIn;
        const defaults: IFieldDateParams = {
            formatString: "",
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldDate.uid],
            ...paramsCaller,
            ...overwrites.project[FieldDate.uid],
            ...overwrites.section[FieldDate.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 Escaped because dates should not have HTML Entities and is printed in some cases
        let text = field.length ? PrintProcessor.getCdataAsText(field[0], true) : "";
        if (params.formatString != "") {
            let date = new Date(text);
            text = new SimpleDateFormat(params.formatString).format(date);
        }

        let rendered = `<div class='${params.class} ${FieldDate.uid}'>${text}</div>`;
        return rendered;
    }
}

PrintProcessor.addFunction(FieldDate.uid, new FieldDate());
