import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { IAttributePrimitiveParams };
export { AttributePrimitive };

interface IAttributePrimitiveParams {
    attributeName?: string; // default:"". the name of the attribute, either: author, birth, birth_customer_format, depth (in tree), ref (item id), serial (numeric part of item id), title, version (revision)
    path?: string; // default:"".  text content (or attribute if attribute is specified as well) of a children of the item
    class?: string; // default:"attribute". class for outermost container
    replace?: IReplaceParam; // default: not set. Match some content with a regex and replace with the given content
}

interface IReplaceParam {
    match: string; // The regex to match the content with
    with: string; // Replacement string for content matched
}

class AttributePrimitive implements IPrintFunction {
    static uid = "attribute";

    getGroup() {
        return "Item";
    }
    getHelp() {
        return `<h1>Renders an attribute of an item</h1>
    <p>Options (either one needs to exist)</p>
<pre>
    attributeName?:string // default:"". the name of the attribute, either: author, birth, birth_customer_format, depth (in tree), ref (item id), serial (numeric part of item id), title, version (revision)
    path?:string // default:"". text content of a children of the item
    class?:string // default:"attribute". class for outermost container
    replace?:{ // default: not set. Match some content with a regex and replace with the given content
        match:string // The regex to match the content with
        with:string // Replacement string for content matched
    }
</pre>`;
    }
    getName() {
        return "Attribute of item";
    }

    private defaults: IAttributePrimitiveParams = {
        class: "attribute",
    };

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IAttributePrimitiveParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[AttributePrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[AttributePrimitive.uid],
            ...overwrites.section[AttributePrimitive.uid],
        });

        if (!params.path && !params.attributeName) {
            onError(`"path" or "attribute" need to be specified`);
            return "";
        }
        if (!params.path) {
            if (params.attributeName) {
                return this.replaceContentIfRequested(params, itemOrFolder.attr(params.attributeName));
            } else {
                return "";
            }
        }
        let follow = $(params.path, itemOrFolder);
        if (follow.length == 0) {
            return "";
        }

        const content = params.attributeName != null ? $(follow[0]).attr(params.attributeName) : $(follow[0]).text();

        const replaced = this.replaceContentIfRequested(params, content);
        return `<span class='${params.class}'>${replaced}</span>`;
    }

    replaceContentIfRequested(params: IAttributePrimitiveParams, content: string): string {
        if (params.replace == null) {
            return content;
        }

        const replaceParam: IReplaceParam = params.replace;
        const regex = new RegExp(replaceParam.match, "g");
        // noinspection TypeScriptValidateJSTypes
        return (content as any).replaceAll(regex, replaceParam.with); // Need to cast to any because typescript does not accept replaceAll on our language level
    }

    editParams(params: IAttributePrimitiveParams, onUpdate: (newParams: IAttributePrimitiveParams) => void) {
        let json = ml.JSON.clone({ ...{}, ...this.defaults, ...params });
        let uiEdit = $("<div>");

        ml.UI.addDropdownToValue(
            uiEdit,
            "Name of attribute",
            json,
            "attributeName",
            [
                { label: "Author", id: "author" },
                { label: "UTC date and time of current revision", id: "birth" },
                { label: "Date and time of current revision", id: "birth_customer_format" },
                { label: "Depth in tree", id: "depth" },
                { label: "Item id", id: "ref" },
                { label: "Number of item id", id: "serial" },
                { label: "Title of item", id: "title" },
                { label: "Revision Number", id: "version" },
            ],
            true,
            true,
            () => {
                onUpdate(json);
            },
            "leave empty for text() content",
        );

        ml.UI.addTextInput(
            uiEdit,
            "Path to field content",
            json,
            "path",
            () => {
                onUpdate(json);
            },
            () => {},
            false,
        );

        return uiEdit;
    }
}

PrintProcessor.addFunction(AttributePrimitive.uid, new AttributePrimitive());
