import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { TitlePrimitive } from "./TitlePrimitive";

export type { IDepthParams };
export { DepthPrimitive };

interface IDepthParams {
    recursionDepth: number; // supplied by caller (depth of iteration)
    offset: number; //  default 0
    style: string; // default "" (numeric, alphabet, Alphabet)
}

class DepthPrimitive implements IPrintFunction {
    static uid = "depth";

    getGroup() {
        return "Depth";
    }
    getHelp() {
        return `<h1>Print current depth of iteration</h1>
<p>Options</p>
<pre>
    offset: number //  default 0: just use depth, other add the value to the depth
    style:string // default "" (numeric, alphabet, Alphabet)
</pre>`;
    }
    getName() {
        return "Print recursion depth";
    }

    private defaults: IDepthParams = {
        recursionDepth: 0, // supplied by caller (depth of iteration)
        offset: 0, //  default 0
        style: "numeric",
    };

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IDepthParams>paramsIn;
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[TitlePrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[TitlePrimitive.uid],
            ...overwrites.section[TitlePrimitive.uid],
        });

        let depth = paramsCaller.recursionDepth + params.offset;
        let depthString = "" + depth;
        if (params.style == "Alphabet") {
            depthString = String.fromCharCode(64 + depth);
        }
        if (params.style == "alphabet") {
            depthString = String.fromCharCode(96 + depth);
        }

        return `<span>${depthString}</span>`;
    }

    editParams(params: IDepthParams, onUpdate: (newParams: IDepthParams) => void) {
        let ui = $("<div>");

        let org = <IDepthParams>ml.JSON.clone({ ...this.defaults, ...params });

        ml.UI.addDropdownNumber(ui, "Offset to actual depth", org, "offset", -10, 10, () => {
            onUpdate(org);
        });

        const styleOpt = [
            { id: "", label: "numeric" },
            { id: "alphabet", label: "small letters" },
            { id: "Alphabet", label: "capital letters" },
        ];
        ml.UI.addDropdownToValue(ui, "Style", org, "style", styleOpt, false, false, () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(DepthPrimitive.uid, new DepthPrimitive());
