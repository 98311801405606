import { ml } from "../../../core/common/matrixlib";
import { IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintLabelIterator, IPrintLabelInfo } from "../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { ILabelsConfig, ILabel } from "../../../core/ProjectSettings";
import { PrintProcessor } from "../PrintProcessor";

export type { ILabelIteratorParams };
export { LabelIterator };

interface ILabelIteratorParams {
    includeNonPrintable?: boolean; // default:false. if set to true also labels without print text will be returned
    excludeSet?: boolean; // default:false. if set to true set labels will not be returned
    excludeUnSet?: boolean; // default:false. if set to true not set labels will not be returned
    includeAllUnSet?: boolean; // default:false. if set to true, an unset printable label are returned even if the labels is configured not to be printed if unset
    positiveList?: string[]; // default:unset. if set only labels with the ids in the list are reported
}

// By default returns all labels which have a print text defined

class LabelIterator implements IPrintLabelIterator {
    worksOnItem = true;
    worksOnFolder = false;

    static uid = "labels";

    getHelp() {
        return `<h1>Iterator over labels of an item</h1>
<p>By default returns all labels which have a print text defined.</p>
<p>Options</p>
<pre>
    includeNonPrintable?:boolean // default:false. if set to true also labels without print text will be returned
    excludeSet?:boolean // default:false. if set to true set labels will not be returned
    excludeUnSet?:boolean // default:false. if set to true not set labels will not be returned
    includeAllUnSet?:boolean // default:false. if set to true, an unset printable label are returned even if the labels is configured not to be printed if unset
    positiveList?:string[] // default:unset. if set only labels with the ids in the list are reported
</pre>`;
    }

    getName() {
        return "Labels of item";
    }

    iterate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: ILabelIteratorParams,
        item: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ): IPrintLabelInfo[] {
        const ifo = globals.itemMap[item];
        if (!ifo) {
            onError(`cannot iterate over labels of unknown item "${item}"`);
            return [];
        }
        let labelJson = <ILabelsConfig>PrintProcessor.getJsonConfig("labels", mf);
        if (!labelJson) {
            // no labels defined
            return [];
        }

        const paramsDefault: ILabelIteratorParams = {};

        const params = ml.JSON.clone({
            ...paramsDefault,
            ...overwrites.customer[LabelIterator.uid],
            ...paramsCaller,
            ...overwrites.project[LabelIterator.uid],
            ...overwrites.section[LabelIterator.uid],
        });

        let category = ifo.closest("category").attr("label");
        let allLabels = labelJson.labels
            ? labelJson.labels.filter((label) => label.categories.indexOf(category) != -1)
            : [];

        let setLabels = $("labels label", ifo)
            .toArray()
            .map((l) => $(l).attr("label"));

        // filter the labels per config

        let reportableLabels = allLabels.filter((label) => {
            if (!label.reportName && !params.includeNonPrintable) {
                // user doesn't want to hear about labels without print text
                return false;
            }
            if (params.excludeSet && setLabels.indexOf(label.label) != -1) {
                // user doesn't want to hear about set labels
                return false;
            }
            if (params.excludeUnSet && setLabels.indexOf(label.label) == -1) {
                // user doesn't want to hear about un set labels
                return false;
            }
            if (!params.includeAllUnSet && label.reportHide && setLabels.indexOf(label.label) == -1) {
                // the label is not set, and the config has the flag set to hide it, if it's not set
                return false;
            }

            if (
                params.positiveList &&
                params.positiveList.length > 0 &&
                params.positiveList.indexOf(label.label) == -1
            ) {
                // the user only wants to hear about some labels
                return false;
            }

            return true;
        });

        let labels: IPrintLabelInfo[] = [];

        for (let label of reportableLabels) {
            let set = setLabels.indexOf(label.label) != -1;
            labels.push({
                id: label.label,
                printName: this.getReportName(label, set),
                icon: this.getIcon(label, set),
                set: set,
                jsonConfig: labelJson, // the json config
            });
        }

        return labels;
    }

    // get report name from config
    protected getReportName(label: ILabel, set: boolean) {
        if (label.reportName) {
            return label.reportName;
        }

        let style = this.getStyle(label, set);

        if (style) {
            if (style.displayName) return style.displayName;
        }

        if (label.displayName) {
            return label.displayName;
        }

        return label.label;
    }

    protected getIcon(label: ILabel, set: boolean) {
        let style = this.getStyle(label, set);

        if (style && style.icon) {
            return style.icon;
        }

        return set ? "fal fa-check" : "fal fa-times";
    }

    // get style configuration, depending on state of label
    protected getStyle(label: ILabel, set: boolean) {
        if (label.style && label.style.label) {
            return set ? label.style.label.on : label.style.label.off;
        }
        return null;
    }

    editParams(params: ILabelIteratorParams, onUpdate: (newParams: ILabelIteratorParams) => void) {
        let ui = $("<div>");

        let org = <ILabelIteratorParams>ml.JSON.clone({ ...{}, ...params });

        ml.UI.addCheckbox(
            ui,
            "Also include labels without configured print text will be returned",
            org,
            "includeNonPrintable",
            () => {
                onUpdate(org);
            },
        );
        ml.UI.addCheckbox(ui, "Exclude unset labels", org, "excludeUnSet", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Exclude set labels", org, "excludeSet", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Include labels configured to not be printed if unset", org, "includeAllUnSet", () => {
            onUpdate(org);
        });

        ml.UI.addDropdownToArray(
            ui,
            "specify a list of labels to be included",
            org,
            "positiveList",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "include all labels",
        );

        return ui;
    }
}

PrintProcessor.addLabelIterator(LabelIterator.uid, new LabelIterator());
