export { FieldHelper };
class FieldHelper {
    static fixTermsAndAbbreviation(text: string, mf: JQuery): string {
        $(mf)
            .children("matrix_filter")
            .children("terms_abbreviations")
            .children("term_abbreviation")
            .each((idx, term_abbreviation) => {
                let replacement = $("with", term_abbreviation).html();

                const tagType = term_abbreviation.getAttribute("tagType");
                //MATRIX-5236 there are old macros without a tag type so we need to handle 0 (empty) as 2
                if (tagType === "2" || tagType === "0") {
                    const tempElem: HTMLElement = document.createElement("div");
                    tempElem.innerHTML = replacement;
                    replacement = tempElem.innerText;
                }
                let macroText = term_abbreviation.getAttribute("what");
                if (macroText) {
                    text = FieldHelper.renderMacroIfNotRenderedBefore(text, macroText, replacement);
                }
            });
        return text;
    }

    static renderMacroIfNotRenderedBefore(text: string, macroText: string, replacement: string): string {
        const macro = `_${macroText}_`;
        const info = `<!-- ${macroText} or ${macro} -->`;
        // This regex does a negative lookbehind -> it will not match on existing replacements, avoiding replacing the macro twice
        const regexToMatch = `(?<!(<!--\\s${macroText}\\sor\\s))${macro}`;
        return text.replace(new RegExp(regexToMatch), info + replacement);
    }
}
