import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintMacroParams } from "../../../core/printinterface/PrintMacros";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../PrintProcessor";

export type { IFieldOfTypeParams };
export { FieldIsOfType };

interface IFieldOfTypeParams extends IPrintMacroParams {
    fieldType: string; // supplied by calling entity
    fieldTypes: string[]; // required, render if fields is one of the given types
    negate: boolean; // default:false. if set: render is not one of th given types
}

class FieldIsOfType implements IConditionFunction {
    itemOrFolder = false;
    static uid = "fieldIsOfType";
    getHelp() {
        return `<h1>FIELD: Render if the field is of a given type</h1>
    <p>Options
<pre>
    fieldTypes:string[], // required, render if fields is one of the given types
    negate: boolean // default:false. if set: render is not one of th given types
</pre>
    </p>`;
    }

    getName() {
        return "FIELD: Render if the field is of a given type";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        params: IFieldOfTypeParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        if (!params.fieldTypes) {
            // condition is met
            return params.negate ? false : true;
        }
        if (params.fieldTypes.indexOf(params.fieldType)) {
            // condition is met
            return params.negate ? false : true;
        }
        // condition is not met
        return params.negate ? true : false;
    }
}

PrintProcessor.addConditionFunction(FieldIsOfType.uid, new FieldIsOfType());
