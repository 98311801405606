import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { IPrintSorter } from "../../../core/printinterface/PrintSorter";
import { PrintProcessor } from "../PrintProcessor";

export { PrintSortByTitle };

class PrintSortByTitle implements IPrintSorter {
    getHelp() {
        return `<h1>Sort by title</h1>`;
    }

    getName() {
        return "Sort by title";
    }

    sort(
        a: string,
        b: string,
        inverse: boolean,
        params: any,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let titleA = globals.itemMap[a][0].getAttribute("title");
        let titleB = globals.itemMap[b][0].getAttribute("title");
        if (titleA && titleB) {
            if (inverse) {
                return titleA < titleB ? 1 : titleA > titleB ? -1 : 0;
            } else {
                return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
            }
        }
        return 0;
    }
}

PrintProcessor.addItemSorter("title", new PrintSortByTitle());
