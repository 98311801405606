import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { ITitlePrimitiveParams };
export { TitlePrimitive };

interface ITitlePrimitiveParams {
    class?: string; // default:"title". class for outermost container
}

class TitlePrimitive implements IPrintFunction {
    static uid = "title";

    getGroup() {
        return "Item";
    }
    getHelp() {
        return `<h1>Renders the title of an item</h1>
<p>Options</p>
<pre>
    class?:string // default:"title". class for outermost container
</pre>`;
    }
    getName() {
        return "Title of item";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: ITitlePrimitiveParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const defaults: ITitlePrimitiveParams = {
            class: "title",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[TitlePrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[TitlePrimitive.uid],
            ...overwrites.section[TitlePrimitive.uid],
        });

        //MATRIX-4981 HTML Entities need to be escaped before printing
        const title = itemOrFolder[0].getAttribute("title") ?? "";
        const escaped = $("<div>").text(title).html();
        return `<span class='${params.class}'>${escaped}</span>`;
    }
}

PrintProcessor.addFunction(TitlePrimitive.uid, new TitlePrimitive());
