import { IPrintFieldInfo, IPrintLabelInfo } from "../../core/printinterface/PrintIterators";
import { IPrintMacro } from "../../core/printinterface/PrintMacros";

export type { IPrintFieldParams, IPrintLabelParams };

// interface for the functions printing something about a field

interface IPrintFieldParams extends IPrintMacro {
    fieldInfo?: IPrintFieldInfo;
}

interface IPrintLabelParams extends IPrintMacro {
    label: IPrintLabelInfo;
}
