import { ml } from "../../../core/common/matrixlib";
import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { ILabelsConfig } from "../../../core/ProjectSettings";
import { ILabelIteratorParams, LabelIterator } from "../iterators/LabelIterator";
import { PrintProcessor } from "../PrintProcessor";

export type { IItemHasLabelConditionParams };
export { ItemHasLabels };

interface IItemHasLabelConditionParams extends ILabelIteratorParams {
    iterator?: string;
    negate: boolean; // negate filter
    labels: string[]; // if set, only the given labels are counted
    setOrUnset: boolean; // if true it also counts unset labels
    includeNonPrintable?: boolean; // default:false. if set to true also labels without print text will be counted
}

class ItemHasLabels implements IConditionFunction {
    itemOrFolder = true;

    static uid = "hasLabels";

    getHelp() {
        return `<h1>ITEM: Render if the item has at least one label set</h1>
<p>Options</p>
<pre>
    iterator:string // iterator to use, defaults to "labels"
    negate:boolean // negate filter
    labels:string[] // if set, only the given labels are counted
    setOrUnset: boolean // if true it also counts unset labels (so it just checks if there could be a label)
    includeNonPrintable?:boolean // default:false. if set to true also labels without print text will be counted
</pre>`;
    }

    getName() {
        return "ITEM: Render if the item has at least one label set";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        params: IItemHasLabelConditionParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let iterator = PrintProcessor.getLabelIterator(params.iterator ? params.iterator : LabelIterator.uid);
        if (!iterator) {
            onError(`"iterator does not exist "${params.iterator}".`);
            return false;
        }
        if (params.setOrUnset) {
            // only look at actually set labels
            let labelJson = <ILabelsConfig>PrintProcessor.getJsonConfig("labels", mf);
            if (!labelJson || !labelJson.labels) {
                // no labels defined
                return false;
            }

            let category = ml.Item.parseRef(itemOrFolderRef).type;
            let allLabels = labelJson.labels.filter((label) => label.categories.indexOf(category) != -1);
            // return true if there is at least one label defined for this category
            return allLabels.length > 0;
        }

        let labels = iterator.iterate(overwrites, params, itemOrFolderRef, mf, globals, possibleTargets, onError);

        // only look at actually set labels
        labels = labels.filter((label) => label.set);

        if (params.labels) {
            labels = labels.filter((label) => params.labels.indexOf(label.id) != -1);
        }

        let hasLabels = labels.length > 0;
        return hasLabels;
    }
}

PrintProcessor.addConditionFunction(ItemHasLabels.uid, new ItemHasLabels());
