import { ml } from "../../../../../core/common/matrixlib";
import { IStringMap } from "../../../../../core/globals";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { LinkPrimitive } from "../../items/LinkPrimitive";
import { FieldHelper } from "../FieldHelper";
import { ColumnEditor } from "../../../../../core/common/businesslogic";

export type { IFieldTableOptions, IFieldTableOptionsCell };
export { FieldTable };

interface IFieldTableOptions extends IPrintFieldParams {
    showSteps: boolean; // default true. if true shows a column with the step number

    columnWidths: string[]; // default:[]. can be used to set the width of columns (css style, e.g. ["3cm","6pt","200px"]

    // user details for user columns - these are shared with other fields
    login: boolean; // default:false. if set to true show login (user id)
    first: boolean; // default:true. if set to true show first name
    last: boolean; // default:true. if set to true show last name
    email: boolean; // default:false. if set to true show email

    cell?: IFieldTableOptionsCell; // allows to specify a cell, e.g. {row:1,col:1} is first row and first column, {row:-2,col:-1} is second last row and last column,
    hideColumns?: number[]; // allows to hide columns, e.g. hide[1,-1] hides the first and last columns
    showRowsMatching?: IStringMap; // allows to hide row if a specific column value match the a REGEX
    classTable: string; // default:"table table-bordered". additional class for outermost container
    class: string; // default:"". additional class for outermost container
}

interface IFieldTableOptionsCell {
    row: number;
    col: number;
}

class FieldTable implements IPrintFunction {
    static MAX_WIDTH = 1790;

    private baseUID: string;
    constructor(classes: string) {
        this.baseUID = classes;
    }
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>table ${this.baseUID}</h2>
<pre>
    showSteps:boolean // default true. if true shows a column with the step number

    columnWidths:string[] // default:[]. can be used to set the width of columns (css style, e.g. ["3cm","6pt","200px"] "" will hide a column completely

    // user details for user columns - these are shared with other fields
    login:boolean // default:false. if set to true show login (user id)
    first:boolean // default:true. if set to true show first name
    last:boolean // default:true. if set to true show last name
    email:boolean // default:false. if set to true show email
    cell?:IFieldTableOptionsCell // allows to specify a cell, e.g. {row:1,col:1} is first row and first column, {row:-2,col:-1} is second last row and last column,
    showRowsMatching?:IStringMap, // allows to hide row if a specific column value match the a REGEX
    hideColumns?:number[] // allows to hide columns, e.g. hide[1,-1] hides the first and last columns

    classTable:string // default:"table table-bordered". additional class for outermost container
</pre>`;
    }
    getName() {
        return "Table field renderer (default implementation)";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let that = this;
        const paramsCaller = <IFieldTableOptions>paramsIn;
        const defaultParams: IFieldTableOptions = {
            showSteps: true,
            classTable: `table table-bordered`,
            login: false,
            first: true,
            last: true,
            email: false,
            columnWidths: [],
            hideColumns: [],
            showRowsMatching: {},
            class: "",
        };

        const params = <IFieldTableOptions>ml.JSON.clone({
            ...defaultParams,
            ...overwrites.customer[this.baseUID],
            ...paramsCaller,
            ...overwrites.project[this.baseUID],
            ...overwrites.section[this.baseUID],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }
        let field = paramsCaller.fieldInfo.field;

        if (
            field.length == 0 ||
            !field[0].childNodes ||
            field[0].childNodes.length < 2 ||
            (field[0].childNodes.length == 2 && $(field[0].childNodes[1]).text() == "")
        ) {
            return "";
        }

        // prepare / get some data
        // paramsCaller.fieldInfo.fieldId
        let itemId = itemOrFolder.attr("ref");
        let category = ml.Item.parseRef(itemId).type;
        let xtcColumns = $(`setting_xml[key='xtc_config'] render ${category} columns`, mf);
        let tableColumns = $(`field_def[field_id=${params.fieldInfo?.fieldId}] param_xml columns`, mf);
        let columnDefinitions = tableColumns.length ? tableColumns : xtcColumns;

        let showSteps =
            params.showSteps &&
            "false" != $(`field_def[field_id=${params.fieldInfo?.fieldId}] param_xml showLineNumbers`, mf).text();
        let showStepsText = $(`field_def[field_id=${params.fieldInfo?.fieldId}] param_xml stepName`, mf).text();
        if (!columnDefinitions.length) {
            onError("No columns defined or very old configuration (1.5 and earlier)");
            return "";
        }

        let hideColumns: number[] = [];
        for (let colIdx of params.hideColumns ? params.hideColumns : []) {
            if (colIdx > 0) {
                hideColumns.push(colIdx - 1); // 0 for first
            } else {
                hideColumns.push(columnDefinitions.length + colIdx);
            }
        }
        let rendered = `<table  class='${params.class} ${params.classTable} ${this.baseUID}'>`;

        rendered += "<thead><tr>";
        if (showSteps && hideColumns.indexOf(0) == -1) {
            rendered += `<th class='tcstep' style='${this.getColumnWidth(params.columnWidths, 0)}'>${
                showStepsText ? showStepsText : "Step"
            }</th>`;
        }
        $.each(columnDefinitions, function (colIdx, colDef) {
            if (hideColumns.indexOf(colIdx + (showSteps ? 1 : 0)) == -1) {
                rendered += `<th style='${that.getColumnWidth(params.columnWidths, colIdx + (showSteps ? 1 : 0))}'>${$(
                    "name",
                    $(colDef),
                ).text()}</th>`;
            }
        });
        rendered += "</tr></thead><tbody>";

        if ($("test_case instruction", params.fieldInfo?.field).length) {
            for (let row of $("test_case instruction", params.fieldInfo?.field).toArray()) {
                rendered += await that.showTableRow(
                    overwrites,
                    showSteps,
                    columnDefinitions,
                    $(row),
                    params,
                    itemOrFolder,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                    hideColumns,
                );
            }
        } else {
            for (let row of $("xml_data array", params.fieldInfo?.field).toArray()) {
                rendered += await that.showTableRow(
                    overwrites,
                    showSteps,
                    columnDefinitions,
                    $(row),
                    params,
                    itemOrFolder,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                    hideColumns,
                );
            }
        }
        rendered += "</tbody>";

        rendered += "</table>";

        if (overwrites.tableRow && params.cell) {
            params.cell.row = overwrites.tableRow;
        }
        if (params.cell && params.cell.row && params.cell.col) {
            let rows = $("tbody tr", $(rendered)).toArray();
            if (params.cell.row > rows.length) return "";
            if (-params.cell.row > rows.length) return "";
            let row = rows[params.cell.row > 0 ? params.cell.row - 1 : rows.length + params.cell.row];
            let tds = $("td", row).toArray();

            if (params.cell.col > tds.length) return "";
            if (-params.cell.col > tds.length) return "";
            let td = tds[params.cell.col > 0 ? params.cell.col - (showSteps ? 1 : 0) : tds.length + params.cell.col];
            return FieldHelper.fixTermsAndAbbreviation(td.innerHTML, mf);
        }
        return FieldHelper.fixTermsAndAbbreviation(rendered, mf);
    }

    private getColumnWidth(columnWidths: string[], colIdx: number) {
        if (!columnWidths || columnWidths.length <= colIdx) {
            return "";
        }
        if (!columnWidths[colIdx]) {
            return "";
        }
        return "width:" + columnWidths[colIdx];
    }

    private async showTableRow(
        overwrites: IGlobalPrintFunctionParams,
        showSteps: boolean,
        columnDefinitions: JQuery,
        instruction: JQuery,
        params: IFieldTableOptions,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
        hideColumns: number[],
    ) {
        let that = this;
        let rr = "<tr>";

        let hide = false;
        $.each(columnDefinitions, function (colIdx, colDef) {
            let colName = $(`field`, colDef).text();
            let colVal = $(`${colName}`, instruction).text();
            if (params.showRowsMatching && params.showRowsMatching[colName] != null) {
                let regex = new RegExp(params.showRowsMatching[colName]);
                if (!regex.test(colVal)) {
                    rr = "<tr style='display:none'>";
                }
            }
        });
        if (showSteps && hideColumns.indexOf(0) == -1) {
            rr += `<td style='${this.getColumnWidth(params.columnWidths, 0)}'>${instruction.attr("step")}</td>`;
        }
        for (let colIdx = 0; colIdx < columnDefinitions.length; colIdx++) {
            let colDef = columnDefinitions[colIdx];

            if (hideColumns.indexOf(colIdx + (showSteps ? 1 : 0)) == -1) {
                let colName = $(`field`, colDef).text();
                let colVal = $(`${colName}`, instruction).text();
                let editor = $("editor", colDef).text();
                let width = `style='${that.getColumnWidth(params.columnWidths, colIdx + (showSteps ? 1 : 0))}'`;

                if (!colVal) {
                    rr += `<td ${width} class="emptyCell"></td>`;
                } else if (editor == ColumnEditor.result) {
                    if ($("render", instruction).text() != "ok") {
                        rr += `<td ${width} class="xtcCell"><span class="failedTestStep">${$(
                            "human",
                            instruction,
                        ).text()}</span></td>`;
                    } else if ($("render", instruction).text() == "ok") {
                        rr += `<td ${width} class="xtcCell"><span class="passedTestStep">${$(
                            "human",
                            instruction,
                        ).text()}</span></td>`;
                    } else {
                        rr += `<td ${width} class="xtcCell"><span class="otherTestStep">${$(
                            "result",
                            instruction,
                        ).text()}</span></td>`;
                    }
                } else if (editor == ColumnEditor.select) {
                    if ($("options setting", colDef).length == 1) {
                        // use a project setting
                        let settingName = $("options setting", colDef).text();
                        let settingOptions = $(`setting_xml[key=${settingName}] options`, mf);
                        let option: any = settingOptions.toArray().filter((dd) => $(`id`, dd).text() == colVal);
                        //MATRIX-4981 HTML Entities need to be escaped before printing - pulling them as html will do this
                        const display = $("label", option[0]).text();
                        const escaped = $("<div>").text(display).html();
                        rr += `<td ${width} class="selectCell">${escaped}</td>`;
                    } else {
                        // its in the column config
                        //MATRIX-4981 HTML Entities need to be escaped before printing - pulling them as html will do this
                        let text = $(`options ${colVal}`, colDef).text();
                        const escaped = $("<div>").text(text).html();
                        rr += `<td ${width} class="selectCell">${escaped}</td>`;
                    }
                } else if (
                    editor == ColumnEditor.user ||
                    editor == ColumnEditor.user_self ||
                    editor == ColumnEditor.self ||
                    editor == ColumnEditor.group
                ) {
                    rr += `<td ${width} class="userCell">${
                        colVal
                            ? PrintProcessor.getUserName(
                                  colVal,
                                  mf,
                                  params.first,
                                  params.last,
                                  params.login,
                                  params.email,
                              )
                            : ""
                    }</td>`;
                } else if (
                    $("options showTitle", colDef).text() == "true" &&
                    (editor == ColumnEditor.design ||
                        editor == ColumnEditor.category ||
                        editor == ColumnEditor.uprules ||
                        editor == ColumnEditor.downrules)
                ) {
                    let itemRefs = colVal.split(",").map((ref) => ref.replace(/<[^>]*>/g, ""));
                    rr += `<td ${width} class="linkCell">`;
                    for (let ref of itemRefs) {
                        if (possibleTargets.indexOf(ref) != -1) {
                            let title = globals.itemMap[ref][0].getAttribute("title");
                            const renderFunction = PrintProcessor.getFunction(LinkPrimitive.uid);
                            let link = renderFunction?.renderAsync(
                                overwrites,
                                ml.JSON.clone({ ...{ titleAfterLink: true }, ...params }),
                                ref,
                                globals.itemMap[ref],
                                mf,
                                globals,
                                possibleTargets,
                                onError,
                            );
                            rr += `<div class="linkCellLink">${await link}</div>`;
                        }
                    }
                    rr += `</td>`;
                } else {
                    rr += `<td ${width} class="${editor}Cell">${colVal}</td>`;
                }
            }
        }

        rr += "</tr>";
        //Replacing Term and abreviation

        return FieldHelper.fixTermsAndAbbreviation(rr, mf);
    }
}

PrintProcessor.addFunction(PrintProcessor.getFieldFunctionId("test_steps"), new FieldTable("test_steps"));
PrintProcessor.addFunction(PrintProcessor.getFieldFunctionId("test_steps_result"), new FieldTable("test_steps_result"));
PrintProcessor.addFunction(PrintProcessor.getFieldFunctionId("steplist"), new FieldTable("steplist"));
