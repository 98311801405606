import { ml } from "../../../core/common/matrixlib";
import { IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintFieldIterator, IPrintFieldInfo } from "../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../PrintProcessor";

export type { IFieldIteratorParams };
export { FieldIterator };

interface IFieldIteratorParams {
    // if none of the below is set all fields are returned // if more than one is set all matches are combined
    showTypes?: string[]; // default:unset. if set it only returns fields of the given types
    showIds?: string[]; // default:unset. if set it only returns fields of the given ids
    showNames?: string[]; // default:unset. if set it only returns fields of the given names
    hideTypes?: string[]; // default:unset. if set it will not return fields of the given types
    hideIds?: string[]; // default:unset. if set it will not return fields of the given ids
    hideNames?: string[]; // default:unset. if set it will not return fields of the given names
    matches?: string; // default unset. if set it will only return fields, of which the text content matches the regex provided
    // additional filters
    onlyWithContent?: boolean; // default:false. if set to true only items with content are returned. To determine if a field has content it is rendered if the result is "" or null it will not be included
}

class FieldIterator implements IPrintFieldIterator {
    worksOnItem = true;
    worksOnFolder = true;

    static uid = "fields";
    // these fields have no value stored inside the item
    static fieldsNoValue = [
        "taskscontrol",
        "workflow",
        "colorpicker",
        "uplinkinfo",
        "links",
        "labels",
        "section",
        "syncstatus",
        "syncsourceinfo",
    ];
    // these fields have values stored in some plugin (like jira or external tasks)
    static fieldsPluginValues = ["taskscontrol"];

    getHelp() {
        return `<h1>Iterator over fields of an item or folder</h1>
<p>Options</p>
<pre>
    // if none of the below is set all fields are returned // if more than one is set all matches are combined
    showTypes?:string[] // default:unset. if set it only returns fields of the given types
    showIds?:string[] // default:unset. if set it only returns fields of the given ids
    showNames?:string[] // default:unset. if set it only returns fields of the given names
    hideTypes?:string[] // default:unset. if set it will not return fields of the given types
    hideIds?:string[] // default:unset. if set it will not return fields of the given ids
    hideNames?:string[] // default:unset. if set it will not return fields of the given names
    matches?:string // default unset. if set it will only return fields, of which the text content matches the regex provided
    // additional filters
    onlyWithContent?:boolean // default:false. if set to true only items with content are returned. To determine if a field has content it is rendered if the result is "" or null it will not be included
</pre>`;
    }

    getName() {
        return "Fields of item or folder";
    }

    async iterate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IFieldIteratorParams,
        itemOrFolder: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ): Promise<IPrintFieldInfo[]> {
        let that = this;

        const ifo = globals.itemMap[itemOrFolder];
        if (!ifo) {
            onError(`cannot iterate over fields of unknown item / folder "${itemOrFolder}"`);
            return [];
        }

        const paramsDefault: IFieldIteratorParams = {};

        const params = ml.JSON.clone({
            ...paramsDefault,
            ...overwrites.customer[FieldIterator.uid],
            ...paramsCaller,
            ...overwrites.project[FieldIterator.uid],
            ...overwrites.section[FieldIterator.uid],
        });

        let fields: IPrintFieldInfo[] = [];

        // get field definition from category or folder from cache (or live if not cached)
        let type = itemOrFolder.indexOf("F-") == 0 ? "FOLDER" : itemOrFolder.split("-")[0];
        let fieldDefs: JQuery = globals.fieldsPerCategory[type];
        if (!fieldDefs) {
            if (type == "FOLDER") {
                // this is a folder (so there's actually no closest category with the field definitions) so the only thing we can do is to enumerate actually existing fields
                fieldDefs = ifo.children("field");
            } else {
                // enumerate all possible fields and cache them
                let category = globals.categories[type];

                fieldDefs = category.children("field_def").filter((idx, fieldDef) => {
                    let fieldType = fieldDef.getAttribute("field_type") || "";
                    return FieldIterator.fieldsNoValue.indexOf(fieldType.toLowerCase()) == -1;
                });
                globals.fieldsPerCategory[type] = fieldDefs;
                $.each(fieldDefs, function (jdx, fd) {
                    let fieldId = fd.getAttribute("field_id");
                    globals.fieldIdByLabel[type + "-" + fd.getAttribute("label").toLowerCase()] = fieldId;
                    globals.fieldDefById[type + "-" + fieldId] = $(fd);
                });
            }
        }

        if (globals.lastItem != itemOrFolder) {
            globals.lastFields = {};
            globals.lastItem = itemOrFolder;

            $.each(ifo.children("FIELD"), function (idx, field) {
                let fieldId = field.getAttribute("field_id");
                let fdj = type == "FOLDER" ? [field] : globals.fieldDefById[type + "-" + fieldId];
                if (fdj) {
                    // else the field is something like an uplink field which is not rendered (see above the special list of excluded fields)
                    let fd = fdj[0];
                    let fieldType = fd.getAttribute("field_type") || "";
                    let label = fd.getAttribute("label") || "";

                    let fieldInfo: IPrintFieldInfo = {
                        fieldId: fieldId,
                        field: $(field), // encoded value of field
                        name: label, // name of field
                        type: fieldType, // type of field
                        config: $(fd), // field configuration
                        jsonConfig: PrintProcessor.getCdataAsJSON(fd),
                        jsonValue: PrintProcessor.getCdataAsJSON(field),
                    };
                    // avoid sorting by numerical id
                    globals.lastFields["f" + fieldId] = fieldInfo;
                }
            });

            if (type != "FOLDER") {
                // add special fields (these are purely "virtual" fields (e.g. jira links) -> so the data is actually not stored in the field but 'somewhere' else )
                // folders don't show as category in the exported XML so no way to get these fields from there... luckily so far only JIRA tickets fall into that class
                // and they cannot be assigned to folders

                let category = globals.categories[type];

                $.each(category.children("field_def"), function (jdx, fd) {
                    let fieldType = fd.getAttribute("field_type") || "";
                    if (FieldIterator.fieldsPluginValues.indexOf(fieldType.toLowerCase()) != -1) {
                        let fieldId = fd.getAttribute("field_id");
                        let fieldInfo: IPrintFieldInfo = {
                            fieldId: fieldId,
                            field: $("<div>"), // there's no direct value
                            name: fd.getAttribute("label"), // name of field
                            type: fd.getAttribute("field_type"), // type of field
                            config: $(fd), // field configuration
                            jsonConfig: PrintProcessor.getCdataAsJSON(fd),
                            jsonValue: {},
                        };
                        // avoid sorting by numerical id
                        globals.lastFields["f" + fieldId] = fieldInfo;
                    }
                });
            }
        }

        for (let ffieldId of Object.getOwnPropertyNames(globals.lastFields)) {
            let field = globals.lastFields[ffieldId];

            let include = true;
            let fieldId = ffieldId.substring(1);
            // check if only to include specific fields (by name or type)

            if (
                (params.showTypes && params.showTypes.length) ||
                (params.showIds && params.showIds.length) ||
                (params.showNames && params.showNames.length)
            ) {
                include = false;
                if (params.showTypes && params.showTypes.length) {
                    include = params.showTypes.map((t: string) => t.toLowerCase()).indexOf(field.type) != -1;
                }
                if (!include && params.showNames && params.showNames.length) {
                    include =
                        params.showNames.map((t: string) => t.toLowerCase()).indexOf(field.name.toLowerCase()) != -1;
                }
                if (!include && params.showIds && params.showIds.length) {
                    include = params.showIds.indexOf(fieldId) != -1;
                }
            }

            if (include) {
                // check if there's an explicit hiding of specific fields (by id, name or type)
                if (params.hideIds && params.hideIds.indexOf(fieldId) != -1) {
                    include = false;
                }
                if (
                    params.hideNames &&
                    params.hideNames.map((t: string) => t.toLowerCase()).indexOf(field.name.toLowerCase()) != -1
                ) {
                    include = false;
                }
                if (
                    params.hideTypes &&
                    params.hideTypes.map((t: string) => t.toLowerCase()).indexOf(field.type.toLowerCase()) != -1
                ) {
                    include = false;
                }
            }

            if (include) {
                if (params.onlyWithContent || params.matches) {
                    let textContent = "";
                    const fieldProcessor = PrintProcessor.getFieldFunction(field.type);
                    if (fieldProcessor) {
                        const renderResult = await fieldProcessor.renderAsync(
                            overwrites,
                            ml.JSON.clone({ ...params, ...{ fieldInfo: field } }),
                            itemOrFolder,
                            ifo,
                            mf,
                            globals,
                            possibleTargets,
                            onError,
                        );
                        const newContent = $("<div>").append(renderResult);
                        textContent = newContent.text();
                    } else {
                        ml.Logger.error(`Could not find a renderer for field type ${field.type}`);
                    }
                    if (params.onlyWithContent) {
                        // pass the field into the render function
                        include = !!textContent;
                    }
                    if (include && params.matches) {
                        include = !!textContent.match(new RegExp(params.matches));
                    }
                }
                if (include) {
                    fields.push(field);
                }
            }
        }
        return fields;
    }

    editParams(params: IFieldIteratorParams, onUpdate: (newParams: IFieldIteratorParams) => void) {
        let ui = $("<div>");

        let org = <IFieldIteratorParams>ml.JSON.clone({ ...{}, ...params });

        ml.UI.addDropdownToArray(
            ui,
            "restrict to list of field types",
            org,
            "showTypes",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "no filtering by type",
        );
        ml.UI.addDropdownToArray(
            ui,
            "restrict to list of field ids (note: these are project specific)",
            org,
            "showIds",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "no filtering by id",
        );
        ml.UI.addDropdownToArray(
            ui,
            "restrict to list of field names",
            org,
            "showNames",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "no filtering by name",
        );

        ml.UI.addDropdownToArray(
            ui,
            "hide these types",
            org,
            "hideTypes",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "not hiding by type",
        );
        ml.UI.addDropdownToArray(
            ui,
            "hide these field ids (note: these are project specific)",
            org,
            "hideIds",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "not hiding by id",
        );
        ml.UI.addDropdownToArray(
            ui,
            "hide these field names",
            org,
            "hideNames",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "not hiding by name",
        );

        ml.UI.addTextInput(
            ui,
            "filter content by a regular expression",
            org,
            "matches",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
            undefined,
            false,
        );

        ml.UI.addCheckbox(ui, "only show fields with content", org, "onlyWithContent", () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFieldIterator(FieldIterator.uid, new FieldIterator());
