import { ml } from "../../../core/common/matrixlib";
import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintFieldInfo } from "../../../core/printinterface/PrintIterators";
import { IPrintMacroParams } from "../../../core/printinterface/PrintMacros";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../PrintProcessor";

export type { IFieldHasContentParams };
export { FieldHasContent };

interface IFieldHasContentParams extends IPrintMacroParams {
    fieldType?: string; // the type of the field (instead of the name)
    fieldName?: string; // the name of the field (instead of the type)
    match: string; // a regular expression on what the content should be
    negate: boolean; // set to true if rendering should be done if a field has no content
    lowerCase: boolean; // to lower case the field content before matching
    matchHTML?: boolean; // match the rendered HTML, not the text content. This is turned on automatically for checkboxes
}

class FieldHasContent implements IConditionFunction {
    itemOrFolder = true;
    static uid = "fieldHasContent";
    getHelp() {
        return `<h1>FIELD: Render if the field has some content</h1>
    <p>Options
<pre>
    fieldType?:string, // the type of the field
    fieldName?:string, // the name of the field
    match:string; // a regular expression on what the content should be
    negate: boolean // default:false. if set: render is not one of th given types
    lowerCase:boolean // to lower case the field content before matching
    matchHTML:boolean // default: false. match the rendered HTML, not the text content. This is turned on automatically for checkboxes
</pre>
    </p>`;
    }

    getName() {
        return "ITEM: Render if a field has some specified content";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        params: IFieldHasContentParams,
        itemOrFolderRef: string,
        ifo: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let matches = false;
        let children = ifo.children("FIELD").toArray();
        for (let child of children) {
            let field = $(child);
            let type = field.attr("field_type");
            let label = field.attr("label");

            if (
                (!params.fieldType || type == params.fieldType) &&
                (!params.fieldName || label.toLowerCase() == params.fieldName.toLowerCase())
            ) {
                // this is the field to be tested
                let fieldProcessor = PrintProcessor.getFieldFunction(type);
                if (fieldProcessor) {
                    let category = ifo.closest("category");
                    let fieldId = field.attr("field_id");
                    let fieldDefs = category.children("field_def[field_id='" + fieldId + "']");

                    let fieldInfo: IPrintFieldInfo = {
                        fieldId: fieldId,
                        field: field,
                        name: label, // name of field
                        type: type, // type of field
                        config: fieldDefs, // field configuration
                        jsonConfig: PrintProcessor.getCdataAsJSON(fieldDefs[0]),
                        jsonValue: PrintProcessor.getCdataAsJSON(child),
                    };

                    let content = await fieldProcessor.renderAsync(
                        overwrites,
                        ml.JSON.clone({ ...params, ...{ fieldInfo: fieldInfo } }),
                        itemOrFolderRef,
                        ifo,
                        mf,
                        globals,
                        possibleTargets,
                        onError,
                    );
                    let matchPlainText = true;
                    if (params.matchHTML) {
                        matchPlainText = false;
                    }
                    if (fieldInfo.type === "checkbox") {
                        // Special case since checkboxes carry their value in the HTML Class
                        matchPlainText = false;
                    }
                    if (matchPlainText) {
                        content = $("<div>").append(content).text();
                    }
                    if (params.lowerCase) {
                        content = content.toLowerCase();
                    }
                    matches = !!content.match(new RegExp(params.match));
                }
            }
        }

        return matches;
    }
}

PrintProcessor.addConditionFunction(FieldHasContent.uid, new FieldHasContent());
