import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { IAuthorPrimitiveParams };
export { AuthorPrimitive };

interface IAuthorPrimitiveParams {
    class: string; // default:"author". class for outermost container
    details?: number; // default 0 (id only), 1 (name only), 2 (email only)
}

class AuthorPrimitive implements IPrintFunction {
    static uid = "author";

    getGroup() {
        return "Item";
    }
    getHelp() {
        return `<h1>Renders the author of an item</h1>
<p>Options</p>
<pre>
    class?:string // default:"author". class for outermost container
    details?:number // default 0 (id only), 1 (name only), 2 (email)
</pre>`;
    }
    getName() {
        return "Author of item";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IAuthorPrimitiveParams>paramsIn;
        const defaults: IAuthorPrimitiveParams = {
            class: "author",
            details: 0,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[AuthorPrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[AuthorPrimitive.uid],
            ...overwrites.section[AuthorPrimitive.uid],
        });

        let userId = object.attr("author");
        let userName =
            params.details == 1
                ? PrintProcessor.getUserName(userId, mf, true, true, false, false)
                : params.details == 2
                ? PrintProcessor.getUserName(userId, mf, false, false, false, true)
                : "";

        return `<span class='${params.class}'>${userName ? userName : userId}</span>`;
    }
}

PrintProcessor.addFunction(AuthorPrimitive.uid, new AuthorPrimitive());
