import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { IIncludePrimitiveParams };
export { IncludePrimitive };

interface IIncludePrimitiveParams extends IPrintFunctionParams {
    class?: string; // default:"includeInfo". class for outermost container
    details?: number; // default 0 (project), 1 (revision), 2 (item-vrevision)
}

class IncludePrimitive implements IPrintFunction {
    static uid = "includeinfo";

    getGroup() {
        return "Item";
    }
    getHelp() {
        return `<h1>Renders include info of item</h1>
<p>Options</p>
<pre>
    class?:string // default:"includeInfo". class for outermost container
    details?:number // default 0 (project), 1 (revision), 2 (item-vrevision)
</pre>`;
    }
    getName() {
        return "Info about source of include";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IIncludePrimitiveParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const defaults: IIncludePrimitiveParams = {
            class: "includeInfo",
            details: 0,
        };

        const params = <IIncludePrimitiveParams>ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[IncludePrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[IncludePrimitive.uid],
            ...overwrites.section[IncludePrimitive.uid],
        });

        let attr = "included_from_project";
        if (params.details == 1) {
            attr = "included_from_version";
        } else if (params.details == 2) {
            attr = "included_from_item";
        }

        let attrVal = object[0].getAttribute(attr);

        return `<span class='${params.class}'>${attrVal ? attrVal : ""}</span>`;
    }
}

PrintProcessor.addFunction(IncludePrimitive.uid, new IncludePrimitive());
