import { ml } from "../../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldTasksParams };
export { FieldTasks };

interface IFieldTasksParams extends IPrintFieldParams {
    showOnlyPlugins?: string[]; // default:unset. if set only links from these plugins are shown
    showDone: boolean; // default:true. if true shows down tickets
    showOpen: boolean; // default:true. if true shows open tickets
    renderID: boolean; // default:false. Prefix task with ID
    useBullets: boolean; // default:true. Render as list with bullets (otherwise render as plain list)
    class: string; // default:"". additional class for outermost container
}

class FieldTasks implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("tasksControl");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>tasks</h2>
<p>Tasks are links to external issue tracking or systems or other websites</p>
<pre>
    showOnlyPlugins?:string[] // default:unset. if set only links from these plugins are shown
    showDone:boolean // default:true. if true shows down tickets
    showOpen:boolean // default:true. if true shows open tickets
    renderID:boolean // default:false. Prefix task with ID
    useBullets:boolean // default:true. Render as list with bullets (otherwise render as plain list)
</pre>`;
    }
    getName() {
        return "Tasks field renderer (default implementation)";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IPrintFieldParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        // note the tasks are actually not saved in the field but in something like
        /*
            <plugin_links>
                <links plugin_id="221" plugin_label="Link">
                    <link>
                        <title>https://www.mysite.de</title>
                        <url>https://www.mysite.de/</url>
                        <done>0</done>
        */

        const defaults: IFieldTasksParams = {
            showDone: true,
            showOpen: true,
            renderID: false,
            useBullets: true,
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldTasks.uid],
            ...paramsCaller,
            ...overwrites.project[FieldTasks.uid],
            ...overwrites.section[FieldTasks.uid],
        });

        let plugins = $("plugin_links", itemOrFolder)
            .toArray()
            .filter((plugin) => {
                return !params.showOnlyPlugins || params.showOnlyPlugins.indexOf($(plugin).attr("plugin_id")) != -1;
            });

        let linklist: string[] = [];
        for (let plugin of plugins) {
            $.each($("links", plugin), function (linksIdx, links) {
                $.each($("link", links), function (linkIdx, link) {
                    let status = $(link).children("done").text();
                    if (!params.showDone && status == "1") {
                        // ignore done
                    } else if (!params.showOpen && status == "0") {
                        // ignore open
                    } else {
                        const url = $(link).children("url").text();
                        let title = $(link).children("title").text();
                        if (params.renderID) {
                            const id = $(link).children("meta_xml").children("key").text();
                            title = id + " " + title;
                        }
                        const line = `<a target="_blank" href="${url}" class="done_${status}">${title}</a>`;
                        if (params.useBullets) {
                            linklist.push(`<li>${line}</li>`);
                        } else {
                            linklist.push(`<div>${line}</div>`);
                        }
                    }
                });
            });
        }

        if (linklist.length == 0) {
            return "";
        }
        const joinedList = linklist.join("");
        if (params.useBullets) {
            return `<ul class='${params.class} ${FieldTasks.uid}'>${joinedList}</ul>`;
        } else {
            return `<div class='${params.class} ${FieldTasks.uid}'>${joinedList}</div>`;
        }
    }
}

PrintProcessor.addFunction(FieldTasks.uid, new FieldTasks());
