import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { ILabelIteratorParams } from "../iterators/LabelIterator";
import { PrintProcessor } from "../PrintProcessor";

export type { IIsIncludeParams };
export { IsInclude };

interface IIsIncludeParams extends ILabelIteratorParams {
    negate: boolean; // negate filter
    projects: string[]; // if set, only the given projects are checked
}

class IsInclude implements IConditionFunction {
    itemOrFolder = true;

    static uid = "isInclude";

    getHelp() {
        return `<h1>ITEM: Render if the item is included from another project</h1>
<p>Options</p>
<pre>
    negate:boolean // negate filter
    projects:string[]  // if set, only the given projects are checked
</pre>`;
    }

    getName() {
        return "ITEM: Render if the item is included from another project";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        params: IIsIncludeParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let project = object[0].getAttribute("included_from_project");
        if (!project) {
            return false; // for sure it's not included
        }
        return !params.projects || params.projects.indexOf(project) != -1;
    }
}

PrintProcessor.addConditionFunction(IsInclude.uid, new IsInclude());
