import { ml } from "../../../../core/common/matrixlib";
import { IRiskParameter, IRiskValue, RiskCalculator } from "../../../../core/common/UI/Controls/riskCtrl2";
import { IReference } from "../../../../core/globals";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { IRiskConfig } from "../../../../core/ProjectSettings";
import { PrintProcessor } from "../../PrintProcessor";

export type { IRiskColorParams };
export { RiskColor };

interface IRiskColorParams {
    background?: boolean /* default false - foreground or background color */;
    before?: boolean /* default false - before or after risk controls */;
}

class RiskColor implements IPrintFunction {
    static uid = "riskcolor";

    getGroup() {
        return "CSS";
    }
    getHelp() {
        return `<h1>Renders the (background) color of a risk depending on its classification before or after risk controls</h1>
<p>Options</p>
<pre>
    background?: boolean    /* default false - foreground or background color */
    before?:boolean /* default false - before or after risk controls */
</pre>`;
    }
    getName() {
        return "Colors of a risk";
    }

    getTemplate() {
        return `color:$['renderFunction':'${RiskColor.uid}','background':false,'before':false]$;background-color:$['renderFunction':'${RiskColor.uid}','background':true,'before':false]$`;
    }

    private defaults: IRiskColorParams = {
        background: false,
        before: false,
    };

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IRiskColorParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[RiskColor.uid],
            ...paramsCaller,
            ...overwrites.project[RiskColor.uid],
            ...overwrites.section[RiskColor.uid],
        });

        let riskFields = item.children("FIELD[field_type='risk2']");
        if (riskFields.length == 0) {
            onError("No risk2 field in item");
            return "";
        }

        // 2+ risk fields are not supported...
        let riskField = riskFields[0];

        // get the json config
        let riskConfig: IRiskConfig;

        let category = item.closest("category");
        let categoryRiskFields = category.children(`field_def[field_type="risk2"]`);
        if (categoryRiskFields.length == 0) {
            onError("No risk2 field in category");
            return "";
        }

        let riskFieldParamStr = categoryRiskFields.find(`param`).text();
        let riskFieldParam = <IRiskParameter>JSON.parse(riskFieldParamStr ? riskFieldParamStr : "{}");
        if (riskFieldParam.riskConfig) {
            riskConfig = riskFieldParam.riskConfig;
        } else {
            // get the global setting
            riskConfig = PrintProcessor.getJsonConfig("risk_config", mf);
        }

        if (!riskConfig) {
            onError("missing risk configuration");
            return "";
        }

        // get the possible downlinks (they need to be selected as possible target and in filter)
        //MATRIX-4981 Should not escape because the content is not unescaped in the filter.xml - did not change
        let riskValue: IRiskValue = JSON.parse(PrintProcessor.getCdataAsText(riskField, false));

        let links: IReference[] = [];
        $.each(riskValue.mitigations, function (idx, mit) {
            if (possibleTargets.indexOf(mit.to) != -1) {
                links.push({ to: mit.to, title: globals.itemMap[mit.to].attr("title") });
            }
        });

        let riskCalculator = new RiskCalculator(riskConfig);
        riskCalculator.init(riskValue);
        riskCalculator.updateMitigations(links);

        return riskCalculator.getAttributeHTML(
            `color${params.before ? "Before" : "After"}${params.background ? "Background" : "Foreground"}`,
        );
    }

    editParams(params: IRiskColorParams, onUpdate: (newParams: IRiskColorParams) => void) {
        let ui = $("<div>");

        // here we need a ...{}, as first parameter to make a new copy
        let p = <any>ml.JSON.clone({ ...{}, ...this.defaults, ...params });

        ml.UI.addCheckbox(ui, "risk before controls", p, "before", () => {
            onUpdate(p);
        });
        ml.UI.addCheckbox(ui, "background color", p, "background", () => {
            onUpdate(p);
        });

        return ui;
    }
}

PrintProcessor.addFunction(RiskColor.uid, new RiskColor());
