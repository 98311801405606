import { ml } from "../../../../../core/common/matrixlib";
import { IRiskValue, RiskCalculator } from "../../../../../core/common/UI/Controls/riskCtrl2";
import { IReference, ControlState } from "../../../../../core/globals";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { IRiskConfig } from "../../../../../core/ProjectSettings";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { LinkPrimitive } from "../../items/LinkPrimitive";
import { FieldHelper } from "../FieldHelper";

export type { IFieldRiskParams };
export { FieldRisk };

interface IFieldRiskParams extends IPrintFieldParams {
    attribute: string; // default:"". if set only the specified attribute will be printed. e.g. harm will print the content of the harm cell. before.severity the severity before controls. "info" will show the possible names in the form
    forcePostWeights: boolean; // default:false. if set to true it will always show the post mitigation weights
    classRiskTable: string; // default:"table". additional class for outermost container
    class: string; // default:"". additional class for outermost container
}

class FieldRisk implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("risk2");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>risk</h2>
<pre>
    attribute:string // default:"". if set only the specified attribute will be printed. e.g. harm will print the content of the harm cell. before.severity the severity before controls. "info" will show the possible names in the form
    forcePostWeights:boolean // default:false. if set to true it will always show the post mitigation weights
    classRiskTable:string // default:"table". additional class for outermost container
</pre>`;
    }
    getName() {
        return "Risk field renderer (default implementation)";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldRiskParams>paramsIn;
        const defaults: IFieldRiskParams = {
            attribute: "",
            forcePostWeights: false,

            classRiskTable: `table`,
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldRisk.uid],
            ...paramsCaller,
            ...overwrites.project[FieldRisk.uid],
            ...overwrites.section[FieldRisk.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        if (
            field.length == 0 ||
            !field[0].childNodes ||
            field[0].childNodes.length < 2 ||
            (field[0].childNodes.length == 2 && $(field[0].childNodes[1]).text() == "")
        ) {
            return "";
        }

        // get the json config
        let riskConfig: IRiskConfig;
        if (paramsCaller.fieldInfo.jsonConfig && paramsCaller.fieldInfo.jsonConfig.riskConfig) {
            riskConfig = paramsCaller.fieldInfo.jsonConfig.riskConfig;
        } else {
            // get the global setting
            riskConfig = PrintProcessor.getJsonConfig("risk_config", mf);
        }

        if (!riskConfig) {
            onError("missing risk configuration");
            return "";
        }

        // get the possible downlinks (they need to be selected as possible target and in filter)
        let riskValue: IRiskValue = paramsCaller.fieldInfo.jsonValue;

        let links: IReference[] = [];
        $.each(riskValue.mitigations, function (idx, mit) {
            if (possibleTargets.indexOf(mit.to) != -1) {
                links.push({ to: mit.to, title: globals.itemMap[mit.to][0].getAttribute("title") || "" });
            }
        });

        if (!params.attribute || params.attribute.toLowerCase() == "info") {
            // use the default renderer
            let renderDummy = $("<div style='display:none'>").appendTo("body");
            if (renderDummy.riskCtrl2) {
                renderDummy.riskCtrl2({
                    controlState: ControlState.Print,
                    canEdit: false,
                    help: "",
                    fieldValue: JSON.stringify(riskValue),
                    parameter: {
                        riskConfig: riskConfig,
                        showAttributeNames: params.attribute == "info",
                        forceAfterWeightsInPrint: params.forcePostWeights,
                    },
                    links: links,
                });
            }
            let dummies = $("._createHyper", renderDummy).toArray();
            for (let createHyper of dummies) {
                const renderFunction = PrintProcessor.getFunction(LinkPrimitive.uid);
                if (renderFunction) {
                    let hyperID = $(createHyper).text();
                    $(createHyper).replaceWith(
                        await renderFunction.renderAsync(
                            overwrites,
                            ml.JSON.clone({ ...{ titleAfterLink: true }, ...params }),
                            hyperID,
                            globals.itemMap[hyperID],
                            mf,
                            globals,
                            possibleTargets,
                            onError,
                        ),
                    );
                }
            }

            let table = $(".risk2", renderDummy);
            if (params.class) {
                table.addClass(params.class);
            }
            if (params.classRiskTable) {
                table.addClass(params.classRiskTable);
            }

            let rendered =
                !params.attribute || params.attribute == "info"
                    ? table[0].outerHTML
                    : $("." + params.attribute, table).html();
            renderDummy.remove();
            rendered = FieldHelper.fixTermsAndAbbreviation(rendered, mf);
            return rendered;
        } else {
            let riskCalculator = new RiskCalculator(riskConfig);
            riskCalculator.init(riskValue);
            riskCalculator.updateMitigations(links);
            let html = riskCalculator.getAttributeHTML(params.attribute);
            // create hype links
            let lks = html.match(/_LINK_[^_]*_LINK_/g);
            if (lks) {
                for (let link of lks) {
                    const renderFunction = PrintProcessor.getFunction(LinkPrimitive.uid);
                    const linkId = link.replace(/_LINK_/g, "");
                    const content = await renderFunction?.renderAsync(
                        overwrites,
                        ml.JSON.clone({ ...{ titleAfterLink: true }, ...params }),
                        linkId,
                        globals.itemMap[linkId],
                        mf,
                        globals,
                        possibleTargets,
                        onError,
                    );
                    html = html.replace(
                        link,
                        // get the links from something like "asdasda_LINK_SPEC-1_LINK_ _LINK_SPEC-2_LINK_sdasda"
                        // and replace it with links everywhere else
                        content ?? "",
                    );
                }
            }

            html = FieldHelper.fixTermsAndAbbreviation(html, mf);

            return html;
        }
    }
}

PrintProcessor.addFunction(FieldRisk.uid, new FieldRisk());
