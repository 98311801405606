import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldUserParams };
export { FieldUser };

interface IFieldUserParams extends IPrintFieldParams {
    login: boolean; // default:false. if set to true show login (user id)
    first: boolean; // default:true. if set to true show first name
    last: boolean; // default:true. if set to true show last name
    email: boolean; // default:false. if set to true show email
    formatUser: "list" | "comma"; // default:comma.  list or comma separated, if set to something other than list or comma it is used as a separator string
    class: string; // default:"". additional class for outermost container
}

class FieldUser implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("User");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>user dropdown</h2>
<pre>
    login:boolean // default:false. if set to true show login (user id)
    first:boolean // default:true. if set to true show first name
    last:boolean // default:true. if set to true show last name
    email:boolean // default:false. if set to true show email
    formatUser:"list"|"comma" // default:comma.  list or comma separated, if set to something other than list or comma it is used as a separator string
</pre>`;
    }
    getName() {
        return "User field renderer (default implementation)";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldUserParams>paramsIn;
        const defaults: IFieldUserParams = {
            login: false,
            first: true,
            last: true,
            email: false,
            formatUser: "comma",
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldUser.uid],
            ...paramsCaller,
            ...overwrites.project[FieldUser.uid],
            ...overwrites.section[FieldUser.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 User IDs should never have HTML Entities
        let users = field.length ? PrintProcessor.getCdataAsText(field[0], true) : "";

        let rendered = `<span class='${params.class} ${FieldUser.uid}'>`;

        if (users) {
            let userNames = users
                .split(",")
                .map((user) =>
                    PrintProcessor.getUserName(user, mf, params.first, params.last, params.login, params.email),
                );

            rendered +=
                params.formatUser == "list"
                    ? "<ul>" + userNames.map((u) => `<li>${u}</li>`).join("") + "</ul>"
                    : userNames.join(params.formatUser == "comma" ? ", " : params.formatUser);
        }

        rendered += "</span>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldUser.uid, new FieldUser());
