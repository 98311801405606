import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldCheckboxParams };
export { FieldCheckbox };

interface IFieldCheckboxParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
    onlyIfSet: boolean; // default:false. Only print checkbox if it is set
}

class FieldCheckbox implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("checkbox");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>checkbox</h2>
<p>Options</p>
<pre>
    onlyIfSet:boolean // default:false. Only print checkbox if it is set
</pre>`;
    }
    getName() {
        return "Checkbox field renderer (default implementation)";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldCheckboxParams>paramsIn;
        const defaults = {
            class: "",
            onlyIfSet: false,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldCheckbox.uid],
            ...paramsCaller,
            ...overwrites.project[FieldCheckbox.uid],
            ...overwrites.section[FieldCheckbox.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }
        let field = paramsCaller.fieldInfo.field;

        let isChecked = false;
        if (field.length && field[0].childNodes && field[0].childNodes.length == 1) {
            if (field[0].childNodes[0].textContent == null) {
                console.error("Expected text content is not available on ", field[0].childNodes[0]);
                throw new Error("Error rendering field");
            }
            isChecked = field[0].childNodes[0].textContent.indexOf("true") != -1;
        }

        if (params.onlyIfSet && !isChecked) {
            return "";
        }
        let rendered = `<span class='${params.class} ${FieldCheckbox.uid} ${
            isChecked ? "checkboxOn" : "checkboxOff"
        }'>`;
        rendered += `<span class="fal ${
            isChecked ? "fa-check" : "fa-times"
        }"></span>&nbsp;<span class="checkboxText">${params.fieldInfo.config.attr("label")}</span>`;
        rendered += "</span>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldCheckbox.uid, new FieldCheckbox());
