import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldTextParams };
export { FieldText };

interface IFieldTextParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

class FieldText implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("text");

    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    getHelp() {
        return `<h2>plain text</h2>
    <p>No options</p>`;
    }
    getName() {
        return "text field";
    }

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldTextParams>paramsIn;
        const defaults: IFieldTextParams = {
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldText.uid],
            ...paramsCaller,
            ...overwrites.project[FieldText.uid],
            ...overwrites.section[FieldText.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 These are unescaped in the filter.xml and need to be escaped for printing
        let text = field.length ? PrintProcessor.getCdataAsText(field[0], true) : "";

        let rendered = `<div style="white-space: pre;" class='${params.class} ${FieldText.uid}'>${text}</div>`;

        return rendered;
    }
}

PrintProcessor.addFunction(FieldText.uid, new FieldText());
