import { ml } from "../../../core/common/matrixlib";
import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintMacroParams } from "../../../core/printinterface/PrintMacros";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { XTCStatus } from "../functions/items/XTCStatus";
import { PrintProcessor } from "../PrintProcessor";

export type { IIsCategoryParams };
export { IsCategory };

interface IIsCategoryParams extends IPrintMacroParams {
    categories: string[]; // a list of categories ["REQ"] or ["REQ","TC"]
    negate: boolean; // negate filter
}

class IsCategory implements IConditionFunction {
    itemOrFolder = true;
    static uid = "isCategory";
    getHelp() {
        return `<h1>ITEM or FOLDER: Render if an item is of a given category</h1>
<p>Options</p>
<pre>
    categories:string[] // a list of categories ["REQ"] or ["REQ","TC"]
    negate:boolean // negate filter
</pre>`;
    }

    getName() {
        return "ITEM or FOLDER: Render if an item is of a given category";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IIsCategoryParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let category = ml.Item.parseRef(itemOrFolderRef).type;

        const params = ml.JSON.clone({
            ...overwrites.customer[XTCStatus.uid],
            ...paramsCaller,
            ...overwrites.project[XTCStatus.uid],
            ...overwrites.section[XTCStatus.uid],
        });

        if (!params.categories || params.categories.indexOf(category) == -1) {
            return false;
        }

        return true;
    }
}

PrintProcessor.addConditionFunction(IsCategory.uid, new IsCategory());
